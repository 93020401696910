/**
 * Team23 Color Theme
 *
 * DO NOT CREATE NEW COLORS THAT ARE NOT DEFINED IN THE DESIGN SYSTEM!
 * https://app.zeplin.io/project/5addee3fc47dfd347758d48c/screen/5ae9c42b9b2863eb663cac57
 */

/////////////////////////
////// Main colors //////
/////////////////////////

$color-primary: #2D5377;
$color-primary-light: #99AABA;
$color-primary-dark: #2E364E;

$color-secondary: #37ADB8;
$color-secondary-dark: #37ADB8;
$color-secondary-light: #EBF8FA;

$color-tertiary: #7B204F;
$color-tertiary-dark: #61193E;

$color-quarternary: #EF4070;

///////////////////////////
////// System colors //////
///////////////////////////

$color-fail: #D95D6C;
$color-fail-light: #FBEEF0;

$color-success: #72C466;
$color-success-light: #F0F9EF;

$color-signal: #FFF200;
$color-warning: #DB9342;

$color-black: #000; // for shadows only

///////////////////////////
//////    Greys      //////
///////////////////////////

$color-white: #FFF;
$color-white-almost: #F9F9F9; // AKA Almost-White

$color-grey-light: #E9EAEA;
$color-grey: #D4D4D4;
$color-grey-dark: #858585;
$color-grey-darker: #637f99;

$color-black-almost: #3E3E3E; // AKA Almost-Black

////////////////////////////
//////      Chart     //////
////////////////////////////

$chart-color: #2D5377;
$chart-color-light: #99AABA;
$chart-color-grey: #858585;
$chart-color-white: #F9F9F9;

////////////////////////////
////// Custom Mapping //////
////////////////////////////

$color-background-navbar: $color-primary-dark;
$color-background-default: $color-white-almost;

//////////////////////////////
//////  Legend Colors  //////
////////////////////////////

$color-red: #f9dfe3;
$sub-color-red: $color-fail;
$color-amber: #fae8d3;
$sub-color-amber: $color-warning;
$color-green: #dcf4d9;
$sub-color-green: $color-success;
$color-aqua-green: #d5ebed;
$sub-color-aqua-green: #1b7787;
$color-blue: #d1e5fd;
$sub-color-blue: #358ff8;
$color-purple: #e9dafd;
$sub-color-purple: #a76bf6;
$sub-color-grey: #9c9c9c;

// May need to move these states
$t23-focus-input-border: 2px solid $color-white;
$t23-focus-border: 2px solid $color-primary;
$t23-focus-border-light: 1px solid $color-secondary;
$t23-focus-background-color: $color-success-light;
