$select-padding: 0 10px;
$select-height: 37px;
$select-border: none;
$select-label-color: $color-primary;

// Allow the panel to grow beyond the trigger width.
.cdk-overlay-pane:has(.mat-mdc-select-panel) { min-width: fit-content; }

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    padding: 0 !important;
    background-color: $color-white !important;
}

.mat-mdc-select {
    height: 100%;
    line-height: inherit;

    &-trigger {
        @include text-medium;

        display: block;
        box-sizing: border-box;
        width: 100%;
        height: $select-height;
        border: $select-border;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: $color-white;
        color: $select-label-color;
        line-height: $select-height;
        outline: none;
        padding: 0 40px 0 10px;

        &::placeholder {
            color: $color-grey-dark;
            font-style: italic;
        }

        &:disabled {
            color: $color-grey-dark;
        }

        &.disabled {
            color: $color-grey-dark;
            cursor: default;
        }
    }

    &-value {
        color: $color-primary;
        vertical-align: middle;
        display: inline;
    }

    // Set a nice green outline and color for a focused mat-select.
    // This does not apply for the navbar dropdown menu (further down), where they should remain transparent.
    &:focus:not(.mat-mdc-select-disabled, .navbar-select) {
        outline: 1px solid $color-secondary;

        .mat-mdc-select-trigger {
            background-color: $t23-focus-background-color;
        }
    }

    &-disabled {
        .mat-mdc-select-value {
            color: $color-grey-dark;
        }
    }
}

ht-select, ht-multi-select {
    .mat-mdc-select {
        // Don't show the opening button in ht-select - we have our own.
        &-arrow-wrapper {
            display: none !important;
        }
    }
}

.mat-mdc-option {
    @include text-medium;

    font-size: 12px !important;
    color: $color-primary !important;

    // Material 16 select options are larger than we like. Limit the height to 3em.
    min-height: 1em !important;
    height: 3em;
    box-sizing: border-box;
    line-height: 3em;

    // Highlight options on hover or on keyboard navigation focus.
    &:hover,
    &-active {
        background-color: $color-white-almost !important;
        color: $color-primary !important;
    }

    // Highlight the currently selected option.
    &.mdc-list-item--selected {
        color: $color-secondary-dark !important;
        font-weight: bold;
    }

    // The select search box should be styled like a text input.
    &.mat-mdc-option[aria-disabled="true"].contains-mat-select-search {
        // Revert the -8px vertical positioning.
        top: 0;
        margin-top: 0;
        background-color: $color-grey-light !important;
        border: 1px solid $color-grey;

        lib-mat-select-search {
            width: 100%;
        }

        .mat-select-search-clear {
            display: none;
        }

        // Both the input and the container should be the same color. This creates the illusion of a single text input that looks just like ours.
        .mat-select-search-input {
            padding: 0 16px;
        }
    }
}

// List items in selects and autocompletes should not break lines, but cut off with ellipsis.
.mdc-list-item {
    &__primary-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
    }
}

.mat-mdc-form-field {
    width: 100%;

    &-flex {
        align-items: center;
    }
}

// Select overrides for specific use cases

.navbar {
    // In the navbar dropdown menu, the selects are colored differently.
    .mat-mdc-select {
        &-trigger {
            // This overrides the focus color and border set in this file for other selects.
            background-color: transparent;
            border: 1px solid $color-grey-dark;
            padding: 0 10px;
        }

        &-value-text {
            color: $color-grey;
        }

        &-arrow {
            color: $color-grey;
        }
    }
}

.quickfilter {
    // In the quickfilter component, the select and search input get a border.
    .mat-mdc-input-element,
    .mat-mdc-select-trigger {
        border: 1px solid $color-grey;
    }
}

.page-selector {
    // In the table component, the page selection should be styled like the pagination size selection.

    .mat-mdc-select-trigger {
        background-color: transparent;
        padding: 0 10px;
    }

    // Remove the empty subscript wrapper from the page selection.
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    // No padding around the select trigger, as it cuts off the value and makes our colorization look bad.
    .mat-mdc-text-field-wrapper {
        padding: 0;
    }
}
