@import 'utilities';

$t23-button-border-radius: 0;

.button-row {
    display: flex;
    margin-bottom: 30px;

    // TODO: don't use tag selector!
    ui-button {
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.modal__content .button-row {
    margin-bottom: 0;
}

button,
[type='submit'] {
    appearance: none !important;
    outline: none;
}
