@import 'utilities';

.p-calendar {
    width: 100%;

    .p-inputtext {
        width: 100% !important;

        &[readonly] {
            cursor: pointer;
        }
    }
}

.p-datepicker {
    $size-col: 30px;

    background-color: $color-white;
    color: $color-primary-dark;
    font-size: .8em;
    min-width: auto !important;
    width: auto !important;
    border-radius: 0;
    border: 1px solid $color-grey-dark;

    &:not(.p-datepicker-inline) {
        background-color: $color-white;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    }

    .p-link {
        border: none;
        background-color: transparent;
    }

    .p-datepicker-header {
        padding: 5px;
        color: $color-white;
        background: $color-secondary-dark;
        font-weight: 700;
        font-family: Arial, sans-serif;
        margin: 0;
        border-bottom: 1px solid $color-grey-light;

        .p-datepicker-prev, .p-datepicker-next {
            color: $color-white;
            width: 20px;
            height: 20px;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:enabled {
                &:hover {
                    background-color: rgb(0 0 0 / 12%);
                }
            }
        }

        .p-datepicker-title {
            .p-datepicker-year, .p-datepicker-month {
                color: $color-white;
                padding: 5px;
                transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
                font-weight: 700;
                box-sizing: border-box;

                &:enabled {
                    &:hover {
                        background-color: rgb(0 0 0 / 12%);
                    }
                }
            }

            .p-datepicker-month {
                margin-right: 10px;
            }
        }
    }

    table {
        margin: 0;

        thead {
            border-bottom: 1px solid $color-grey-light;
        }

        tr:hover {
            background-color: transparent;
        }

        th {
            color: $color-primary-light;
            padding: 1em 0;
            font-size: .8em;
            font-weight: initial;
            border: none;
            text-align: center;

            > span {
                width: $size-col;
                height: $size-col;
            }
        }

        td {
            padding: 0;
            border: none;
            width: $size-col + 10px;
            height: $size-col + 10px;

            > span {
                width: $size-col + 5px;
                height: $size-col + 5px;
                border-radius: 50%;
                transition: box-shadow 0.2s;
                border: 1px solid transparent;
            }

            > span.p-highlight {
                color: $color-white;
                background-color: $color-secondary-dark;
            }
        }

        td.p-datepicker-today {
            > span {
                border-color: $color-primary-light;
            }

            > span.p-highlight {
                border-color: transparent;
                box-shadow: inset 0 0 0 1px rgb(255 255 255 / 85%);
            }
        }
    }

    .p-datepicker-buttonbar {
        padding: 1rem 0;
        border-top: 1px solid;
        border-top-color: $color-grey-light;

        .p-button {
            width: auto;
        }
    }

    .p-timepicker {
        border-top: 1px solid;
        border-top-color: $color-grey-light;
        padding: 5px;

        button {
            color: $color-primary-dark;
            width: 20px;
            height: 20px;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:enabled {
                &:hover {
                    color: $color-primary-dark;
                    background-color: rgb(0 0 0 / 4%);
                    border-color: transparent;
                }
            }
        }

        > div {
            padding: 0 10px;
        }
    }

    .p-monthpicker {
        margin: 10px 0;

        .p-monthpicker-month {
            padding: 10px;
            box-sizing: border-box;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

        .p-monthpicker-month.p-highlight {
            color: $color-white;
            background-color: $color-secondary-dark;
        }
    }

    .p-yearpicker {
        margin: 10px 0;

        .p-yearpicker-year {
            padding: 10px;
            box-sizing: border-box;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

        .p-yearpicker-year.p-highlight {
            color: $color-white;
            background-color: $color-secondary-dark;
        }
    }

    .p-disabled {
        opacity: 0.5;
    }

    &:not(.p-disabled) {
        table {
            td {
                span {
                    &:not(.p-highlight) {
                        &:not(.p-disabled) {
                            &:hover {
                                background-color: rgb(0 0 0 / 4%);
                            }
                        }
                    }
                }
            }
        }

        .p-monthpicker {
            .p-monthpicker-month {
                &:not(.p-disabled) {
                    &:not(.p-highlight) {
                        &:hover {
                            background-color: rgb(0 0 0 / 4%);
                        }
                    }
                }
            }
        }

        .p-yearpicker {
            .p-yearpicker-year {
                &:not(.p-disabled) {
                    &:not(.p-highlight) {
                        &:hover {
                            background-color: rgb(0 0 0 / 4%);
                        }
                    }
                }
            }
        }
    }
}

.p-datepicker.p-datepicker-timeonly {
    .p-timepicker {
        border-top: 0 none;
    }
}

.p-datepicker.p-datepicker-multiple-month {
    .p-datepicker-group {
        border-left: 1px solid;
        border-left-color: $color-grey-light;
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
            border-left: 0 none;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}
